import React, { useEffect, useState } from "react";

import Apps from "@mui/icons-material/Apps";
import { makeStyles } from "tss-react/mui";

import { UserApplication } from "@cloudentity/acp-public";

import SearchInput from "../../../admin/components/common/SearchInput";
import identitySelfApi from "../../services/identitySelfApi";
import { openUrlInNewTab } from "../../utils/browser.utils";
import AppAvatar from "../AppAvatar";
import OverflowTooltip from "../OverflowTooltip";
import Progress from "../Progress";
import SelectablePaper from "../SelectablePaper";
import { notifyErrorOrDefaultTo } from "../notifications/notificationService";
import { useCommonStyles } from "./utils";

const useStyles = makeStyles()(theme => ({
  container: {
    maxWidth: 960,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    gap: 16,
    marginTop: 32,
  },
  card: {
    width: 250,
    height: 100,
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px 12px",
  },
  logo: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  name: {
    width: "100%",
    textAlign: "center",
    marginTop: 16,
  },
}));

export default function SelfServiceApplications() {
  const { cx, classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const [applications, setApplications] = useState<UserApplication[]>([]);
  const [progress, setProgress] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setProgress(true);
    identitySelfApi
      .listUserApplications()
      .then(res => {
        setApplications(res.data.applications ?? []);
      })
      .catch(err => {
        notifyErrorOrDefaultTo("Error occurred while trying to fetch applications")(err);
        return null;
      })
      .finally(() => {
        setProgress(false);
      });
  }, []);

  const filteredApplications = applications.filter(v =>
    v.name?.toLowerCase().includes(searchText.toLowerCase())
  );

  return progress ? (
    <Progress style={{ marginTop: 64 }} />
  ) : (
    <div className={cx(commonClasses.container, classes.container)}>
      <SearchInput
        value={searchText}
        placeholder="Search"
        onSearch={setSearchText}
        onClear={() => setSearchText("")}
        size="small"
      />
      <div className={classes.cards}>
        {filteredApplications.length === 0 && <div style={{ color: "gray" }}>No applications</div>}
        {filteredApplications.map(app => (
          <div className={classes.card} key={app.id}>
            <Card app={app} />
          </div>
        ))}
      </div>
    </div>
  );
}

function Card({ app }: { app: UserApplication }) {
  const { classes } = useStyles();

  return (
    <SelectablePaper
      id={app.id}
      selectable
      onSelect={() => openUrlInNewTab(app.url)}
      style={{ height: "100%" }}
    >
      <div className={classes.content}>
        {app.logo_uri ? (
          <img src={app.logo_uri} alt="client-logo" style={{ height: 32 }} />
        ) : (
          <AppAvatar>
            <Apps className={classes.logo} />
          </AppAvatar>
        )}
        <div className={classes.name}>
          <OverflowTooltip value={app.name} />
        </div>
      </div>
    </SelectablePaper>
  );
}
