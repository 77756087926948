import React, { ReactNode } from "react";

import FingerprintIcon from "@mui/icons-material/Fingerprint";
import LaptopIcon from "@mui/icons-material/Laptop";
import PasswordIcon from "@mui/icons-material/Password";
import PinOutlined from "@mui/icons-material/PinOutlined";
import sha256 from "crypto-js/sha256";
import { Smartphone } from "react-feather";
import { makeStyles } from "tss-react/mui";

import {
  SelfUserVerifiableAddressTypeEnum,
  SelfUserWithDataV2,
  SelfUserWithDataV2AuthenticationMechanismsEnum,
} from "@cloudentity/acp-identityself";
import { UserinfoResponse } from "@cloudentity/acp-oauth2";
import { ClientDetails, ListClientsWithAccess, UserSession } from "@cloudentity/acp-public";

import { decodeBase64url } from "../../auth/reducers/auth";
import { getFromLocalStorage } from "../../utils/localStorage.utils";

export const useCommonStyles = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  container: {
    padding: 32,
    width: "100%",
    overflowY: "auto",
    height: "100%",
  },
  mainGrid: {
    maxWidth: 1060,
  },
  paper: {
    padding: 32,
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  empty: {
    color: "gray",
  },
  sidePanel: {
    padding: 32,
    background: "#F7FAFF",
  },
  sidePanelMainHeader: {
    justifyContent: "center",
    marginBottom: 24,
  },
  sidePanelHeader: {
    textTransform: "uppercase",
    color: "#212533",
    marginBottom: 16,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
  },
  sidePanelHeaderIcon: {
    marginRight: 8,
  },
  icon: {
    borderRadius: "50%",
    border: `solid 1px ${theme.palette.primary.main}`,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    marginRight: 8,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  infoPanelRowIcon: {
    marginRight: 16,
    color: theme.palette.secondary.light,
  },
}));

export enum CardsEnum {
  "profile" = "profile",
  "sign-in-methods" = "sign-in-methods",
  "devices" = "devices",
  "consents" = "consents",
  "sign-in-identifiers" = "sign-in-identifiers",
}

export enum TabsEnum {
  "applications" = "applications",
  "profile" = "profile",
  "security" = "security",
  "privacy" = "privacy",
}

export type ConfigType = {
  withDialog: boolean;
  withApps?: boolean;
  showMetadata: boolean;
};

export enum FactorType {
  "first-factor" = "first-factor",
  "second-factor" = "second-factor",
}

export type ParsedClient = {
  granted_scopes:
    | {
        scope_display_name: string | undefined;
        scope_description: string | undefined;
        scope_id: any;
      }[]
    | undefined;
  client?: ClientDetails | undefined;
};

export function parseClients(data: ListClientsWithAccess) {
  const withScopes =
    data.clients?.map(c => ({
      ...c,
      granted_scopes: c.granted_scopes?.map(gs => {
        const scope = data.scopes?.find(s => s.scope_name === gs.scope_name) || {
          scope_display_name: gs.granted_scope_name ?? "",
          scope_description: "",
          scope_id: (gs as any).scope_id ?? "",
        };

        return {
          scope_display_name: gs.is_dynamic
            ? `${scope.scope_display_name}: ${gs.granted_scope_name}`
            : scope.scope_display_name,
          scope_description: scope.scope_description,
          scope_id: scope.scope_id,
        };
      }),
    })) ?? [];

  return withScopes;
}

export function calcCurrentSession(sid: string, sessionData: UserSession[]) {
  try {
    const idToken = getFromLocalStorage("id_token");

    const parsedJwt = JSON.parse(decodeBase64url(idToken?.split(".")[1]));
    const aud = parsedJwt.aud;

    const sessionsWithHashes = sessionData.map(session => ({
      ...session,
      hash: sha256(session.id + aud).toString(),
    }));
    const currentSession = sessionsWithHashes.find(session => session.hash === sid);

    return currentSession?.id ?? null;
  } catch {
    return null;
  }
}

export const credentialTypeNameMapper: Record<
  SelfUserWithDataV2AuthenticationMechanismsEnum,
  { icon: ReactNode; name: string }
> = {
  [SelfUserWithDataV2AuthenticationMechanismsEnum.Password]: {
    icon: <PasswordIcon style={{ fontSize: 16 }} />,
    name: "Password",
  },
  [SelfUserWithDataV2AuthenticationMechanismsEnum.Webauthn]: {
    icon: <FingerprintIcon style={{ fontSize: 20 }} />,
    name: "Passkey",
  },
  [SelfUserWithDataV2AuthenticationMechanismsEnum.Totp]: {
    icon: <Smartphone style={{ fontSize: 20 }} />,
    name: "Authenticator App",
  },
  [SelfUserWithDataV2AuthenticationMechanismsEnum.Otp]: {
    icon: <PinOutlined style={{ fontSize: 20 }} />,
    name: "Verification Code",
  },
};

export function getDeviceIcon(
  type: "mobile" | "tablet" | "smarttv" | "wearable" | "embedded" | string | undefined
) {
  if (type === "mobile" || type === "tablet") {
    return <Smartphone />;
  }
  return <LaptopIcon style={{ fontSize: 16 }} />;
}

export function getEmailForTotp(
  userInfoData: UserinfoResponse | undefined,
  userData: SelfUserWithDataV2 | null
) {
  const verifiedEmail = userData?.verifiable_addresses?.find(
    v => v.verified && v.type === SelfUserVerifiableAddressTypeEnum.Email
  )?.address;
  const unverifiedEmail = !verifiedEmail
    ? userData?.verifiable_addresses?.find(v => v.type === SelfUserVerifiableAddressTypeEnum.Email)
        ?.address
    : undefined;

  return verifiedEmail || unverifiedEmail || userInfoData?.email || userInfoData?.name || "";
}
